'use client';

import type { TextareaProps } from '@ngg/components';
import { Textarea as NGGTextarea } from '@ngg/components';
import { cn } from '@ngg/storefront-utils';

export default function Textarea({
  classNames,
  endAdornment,
  label,
  id,
  required,
  placeholder = '',
  ...props
}: TextareaProps) {
  return (
    <NGGTextarea
      {...props}
      required={required}
      placeholder={placeholder}
      id={id}
      endAdornment={
        <>
          {label ? (
            <label
              htmlFor={id}
              className={cn(
                'text-grey-300 pointer-events-none absolute bottom-2.5 left-4 top-3 text-sm',
                'transition-transform peer-focus:-translate-y-3 peer-focus:text-xxxs',
                'peer-[&:not(:placeholder-shown)]:-translate-y-3 peer-[&:not(:placeholder-shown)]:text-xxxs',
                classNames?.label,
              )}>
              {label}
              {required && '*'}
            </label>
          ) : null}
          {endAdornment}
        </>
      }
      classNames={{
        ...classNames,
        textarea: cn(
          'bg-white w-full peer px-4  py-3.5 h-32 text-sm border border-gray-600 placeholder:text-transparent focus:placeholder:text-grey-300',
          'focus:outline-none',
          classNames?.textarea,
        ),
      }}
    />
  );
}
