'use client';

import { Listbox, Transition } from '@headlessui/react';
import { cn } from '@ngg/storefront-utils';
import Icon from '@ui/components/icon/icon';
import { Fragment, useState } from 'react';

export default function Select({
  children,
  options,
  name,
  required = false,
  disabled = false,
  labelIcon,
  optionIcon,
  preSelectedOption,
  classNames = {},
  placeholder,
}: {
  children?: React.ReactNode;
  placeholder: string;
  name: string;
  options: { id: any; value: string; label: string }[];
  required?: boolean;
  disabled?: boolean;
  labelIcon?: ({
    label,
    id,
    value,
  }: {
    label: string;
    id: any;
    value: string;
  }) => React.ReactNode;
  optionIcon?: ({
    label,
    id,
    value,
  }: {
    label: string;
    id: any;
    value: string;
  }) => React.ReactNode;
  preSelectedOption?: { id: string; value: string; label: string };
  classNames?: {
    wrapper?: string;
    button?: string;
    option?: string;
  };
}) {
  const [selectedOption, setSelectedOption] = useState<
    typeof options[0] | null
  >(preSelectedOption || null);

  return (
    <>
      <Listbox
        value={selectedOption}
        onChange={setSelectedOption}
        disabled={disabled}>
        <div
          className={cn(
            'relative w-full self-start bg-white text-black',
            classNames.wrapper,
          )}>
          <input
            type="text"
            name={name}
            required={required}
            disabled={disabled}
            className="pointer-events-none absolute inset-0 select-none appearance-none bg-transparent text-transparent opacity-0 shadow-none focus:outline-none"
            value={selectedOption?.value ?? ''}
            readOnly
            tabIndex={-1}
            aria-hidden="true"
            aria-readonly="true"
            autoComplete="off"
          />
          <Listbox.Button
            className={cn(
              'group flex h-10 w-full items-center border border-gray-600 bg-inherit px-4 text-sm text-inherit',
              'peer:dirty:invalid:border-error focus:outline-none',
              classNames.button,
            )}>
            {() => (
              <>
                {selectedOption && labelIcon ? (
                  <div className="mr-2 h-4 w-4 flex-shrink-0">
                    {labelIcon(selectedOption)}
                  </div>
                ) : null}
                {selectedOption?.label ? (
                  <span>{selectedOption?.label}</span>
                ) : (
                  <span className="text-grey-300">{placeholder}</span>
                )}
                <Icon
                  name="downArrow"
                  color="black"
                  size={16}
                  className={cn([
                    'ml-auto flex-shrink-0 text-base text-inherit transition-transform group-aria-expanded:-rotate-180',
                    !selectedOption?.label && 'text-grey-300',
                  ])}
                  aria-hidden="true"
                />
              </>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute top-full z-10 mt-0 max-h-40 w-full -translate-y-px overflow-auto border border-black bg-inherit py-1 pl-0 text-inherit focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  value={option}
                  className={cn(
                    'ui-active:bg-gray-100 flex cursor-pointer items-center space-x-2 px-4 py-1 text-sm',
                    classNames.option,
                  )}>
                  {optionIcon ? (
                    <div className="mr-2 h-4 w-4 flex-shrink-0">
                      {optionIcon(option)}
                    </div>
                  ) : null}
                  {option.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {children}
    </>
  );
}
