'use client';

import { ValidationError, useForm } from '@formspree/react';
import { useCallback, useMemo, useState } from 'react';
import { Dictionary } from '@ts/dictionary';
import { Maybe } from 'graphql/jsutils/Maybe';
import xss from 'xss';
import { cn } from '@ngg/storefront-utils';
import Input from '@ui/components/inputs/Input';
import Select from '@ui/components/inputs/Select';
import { Button } from '@ngg/components';
import { Heading } from '@ui/components/Heading/Heading';

const consultations = [
  {
    id: 1,
    value: 'stockholm',
    label: 'Gamla Nynäsvägen Skogås',
  },
  {
    id: 2,
    value: 'oslo',
    label: 'Karenslyst Allé Oslo',
  },
  {
    id: 4,
    value: 'digitalMeeting',
    label: 'I want to book a digital meeting',
  },
];

export default function BookMeeting({
  className,
  reference,
  dictionary,
}: {
  className?: string;
  reference?: Maybe<string>;
  dictionary: Dictionary;
}) {
  const [state, handleSubmit] = useForm(reference || '');
  const [email, setEmail] = useState<string | undefined>(undefined);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  const isInputError = useCallback(
    (inputName: string) =>
      // @ts-ignore
      state?.errors?.some((error) => error.field === inputName),
    [state.errors],
  );

  const bookMeetingFormSuccessText = useMemo(
    () =>
      dictionary.bookMeetingFormSuccessText.replace(
        '%EMAIL%',
        `<strong>${email}</strong>`,
      ),
    [email, dictionary.bookMeetingFormSuccessText],
  );

  if (state.succeeded) {
    return (
      <>
        <Heading className="text-lg">
          {dictionary.bookMeetingFormSuccessHeading}
        </Heading>
        <p
          className="mt-4 text-sm"
          dangerouslySetInnerHTML={{
            __html: xss(bookMeetingFormSuccessText),
          }}
        />
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit} className={cn('max-w-4xl', className)}>
      {dictionary.bookMeetingFormHeading && (
        <Heading as="h3" className="w-full text-lg font-medium">
          {dictionary.bookMeetingFormHeading}
        </Heading>
      )}
      <div className="mt-6 space-y-3">
        <Input
          id="name"
          name="name"
          type="text"
          required
          fullWidth
          label={dictionary.formNameInputLabel}
          classNames={{
            input: isInputError('name') ? 'border border-error' : '',
          }}
        />
        <ValidationError
          className="mt-2 text-sm"
          field="name"
          errors={state.errors}
        />
        <Input
          id="email"
          name="email"
          type="email"
          required
          fullWidth
          label={dictionary.formEmailInputLabelShort}
          classNames={{
            input: isInputError('email') ? 'border border-error' : '',
          }}
          onChange={(event) => handleChange(event)}
        />
        <ValidationError
          className="mt-2 text-sm"
          field="email"
          errors={state.errors}
        />
        <Input
          id="phoneNumber"
          name="phoneNumber"
          type="tel"
          fullWidth
          label={dictionary.contactFormPhoneInputLabel}
          classNames={{
            input: isInputError('phoneNumber') ? 'border border-error' : '',
          }}
        />
        <ValidationError
          className="mt-2 text-sm"
          field="email"
          errors={state.errors}
        />
        <Select
          name="consultation"
          placeholder={dictionary.bookMeetingFormConsultationPlaceholder}
          options={consultations}>
          <ValidationError
            className="mt-2 text-sm"
            field="room"
            errors={state.errors}
          />
        </Select>
      </div>

      <Button
        type="submit"
        disabled={state.submitting}
        fullWidth
        className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 md:w-[340px] mt-4 h-10 w-full grow bg-black text-sm text-white disabled:opacity-100 md:mt-6">
        {dictionary.bookMeetingFormSubmitButton}
      </Button>
      {/* Honeypot */}
      <input type="text" name="_gotcha" className="hidden" />
    </form>
  );
}
