'use client';

import { ValidationError, useForm } from '@formspree/react';
import { Dictionary } from '@ts/dictionary';
import { useMemo, useState } from 'react';
import Input from '../../components/inputs/Input';
import Select from '../../components/inputs/Select';
import Textarea from '../../components/inputs/Textarea';
import { Button } from '@ngg/components';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';

export default function Claims({
  dictionary,
  reference,
}: {
  reference?: string | null | undefined;
  dictionary: Dictionary;
}) {
  const [state, handleSubmit] = useForm(reference || '');
  const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null);

  const reasons = useMemo(
    () => [
      {
        id: 1,
        value: dictionary?.claimsFormValueBroken,
        label: dictionary?.claimsFormLabelBroken,
      },
      {
        id: 2,
        value: dictionary?.claimsFormValueNotWorking,
        label: dictionary?.claimsFormLabelNotWorking,
      },
      {
        id: 3,
        value: dictionary?.claimsFormValueNotAsDescribed,
        label: dictionary?.claimsFormNotAsDescribed,
      },
    ],
    [dictionary],
  );

  if (state.succeeded) {
    return (
      <>
        <Heading className="text-lg">{dictionary?.formSuccessHeading}</Heading>
        <Paragraph className="mt-4 text-sm">
          {dictionary?.formSuccessText}
        </Paragraph>
      </>
    );
  }

  const fileNames =
    uploadedFiles && [...uploadedFiles]?.map((file) => file.name).join(', ');
  const isInputError = (inputName: string) =>
    // @ts-ignore
    state?.errors?.some((error) => error.field === inputName);

  return (
    <form
      className="not-prose max-w-4xl space-y-3 text-sm"
      onSubmit={handleSubmit}
      encType="multipart/form-data">
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        <div>
          <Input
            name="Email"
            id="email"
            type="email"
            required
            fullWidth
            label={dictionary?.formEmailLabel}
            classNames={{
              input: isInputError('Email') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Email"
            errors={state.errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label={dictionary?.formOrderNumberLabel}
            required
            fullWidth
            name="Order Number"
            hintId="orderNumberHint"
            hint={dictionary?.formOrderNumberHint}
            classNames={{
              input: isInputError('Order Number') ? 'border-error' : '',
              hint: 'max-md:pl-0.5',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Order Number"
            errors={state.errors}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        <div>
          <Input
            type="number"
            label={dictionary?.claimsFormArticleNumberLabel}
            required
            fullWidth
            name="Item Number"
            hintId="articleNumberHint"
            classNames={{
              input: isInputError('Item Number') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Item Number"
            errors={state.errors}
          />
        </div>
        <div>
          <Select
            name="Reason"
            placeholder={dictionary?.claimsFormReturnReasonLabel}
            required
            options={reasons}>
            <ValidationError
              className="mt-2 text-sm"
              field="Reason"
              errors={state.errors}
            />
          </Select>
        </div>
      </div>

      <Textarea
        label={dictionary?.claimsFormTextareaLabel}
        required
        name="Description"
        fullWidth
        classNames={{
          textarea: isInputError('Description') ? 'border-error' : '',
        }}
      />
      <ValidationError
        className="mt-2 text-sm"
        field="Description"
        errors={state.errors}
      />

      <div className="relative">
        <Input
          type="text"
          readOnly
          label={fileNames || dictionary?.claimsFormUploadInputLabel}
          fullWidth
          classNames={{
            label: uploadedFiles?.length ? 'text-black' : '',
            input: isInputError('Photos') ? 'border-error' : '',
          }}
          endAdornment={
            <div className="Paragraph-2 pointer-events-none absolute bottom-2 right-2 top-2 flex items-center bg-black text-xs font-medium text-white">
              {dictionary?.formUploadEndAdornmentText}
            </div>
          }
        />

        <input
          name="Photos"
          id="file"
          type="file"
          onChange={(e) => setUploadedFiles(e.target.files)}
          className="absolute inset-0 opacity-0"
          accept="image/png, image/gif, image/jpeg"
          multiple
        />
        <ValidationError
          className="mt-2 text-sm"
          field="Photos"
          errors={state.errors}
        />
      </div>

      <div className="mt-10 grid grid-cols-1 gap-3 md:grid-cols-2">
        <Button
          type="submit"
          disabled={state.submitting}
          fullWidth
          className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 h-10 w-full grow bg-black text-sm text-white disabled:opacity-100 md:w-[340px]">
          {dictionary?.claimsFormActionButtonText}
        </Button>
      </div>
    </form>
  );
}
