'use client';

import { ValidationError, useForm } from '@formspree/react';
import { Dictionary } from '@ts/dictionary';
import { useMemo, useState } from 'react';
import Input from '@ui/components/inputs/Input';
import Select from '@ui/components/inputs/Select';

import { Button, Checkbox } from '@ngg/components';
import Icon from '@ui/components/icon/icon';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';

export default function Return({
  dictionary,
  reference,
}: {
  reference?: string | null | undefined;
  dictionary: Dictionary;
}) {
  const [state, handleSubmit] = useForm(reference || '');
  const [items, setItems] = useState<number[]>(() => [new Date().getTime()]);

  const reasons = useMemo(
    () => [
      {
        id: 1,
        value: dictionary?.returnFormValueExpectation,
        label: dictionary?.returnFormLabelExpectation,
      },
      {
        id: 2,
        value: dictionary?.returnFormValueNotSuitable,
        label: dictionary?.returnFormLabelNotSuitable,
      },
      {
        id: 3,
        value: dictionary?.returnFormValueMultiple,
        label: dictionary?.returnFormLabelMultiple,
      },
      {
        id: 4,
        value: dictionary?.returnFormValueOther,
        label: dictionary?.returnFormLabelOther,
      },
    ],
    [dictionary],
  );

  if (state.succeeded) {
    return (
      <div>
        <Heading className="text-lg">{dictionary?.formSuccessHeading}</Heading>
        <Paragraph className="mt-4 text-sm">
          {dictionary?.formSuccessText}
        </Paragraph>
      </div>
    );
  }
  const isInputError = (inputName: string) =>
    // @ts-ignore
    state?.errors?.some((error) => error.field === inputName);

  return (
    <form
      className="not-prose mt-4 max-w-4xl text-sm md:mt-6"
      onSubmit={handleSubmit}>
      <fieldset className="grid grid-cols-1 gap-3 md:grid-cols-2">
        <div>
          <Input
            type="email"
            label={dictionary?.formEmailLabel}
            required
            id="email"
            name="email"
            fullWidth
            data-1p-ignore
            classNames={{
              input: isInputError('email') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="email"
            errors={state.errors}
          />
        </div>
        <div>
          <Input
            type="number"
            label={dictionary?.formOrderNumberLabel}
            required
            id="OrderNo"
            name="OrderNo"
            fullWidth
            hint={dictionary?.formOrderNumberHint}
            inputMode="numeric"
            data-1p-ignore
            classNames={{
              input: isInputError('OrderNo') ? 'border-error' : '',
              hint: 'max-md:pl-0.5',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="OrderNo"
            errors={state.errors}
          />
        </div>
      </fieldset>
      <Paragraph className="mb-1 mt-4 text-xxs">
        {dictionary?.returnFormInfoText}
      </Paragraph>
      <div>
        {items.map((item, index) => (
          <fieldset key={item} className="pb-2 first:border-t-0">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
              <div>
                <Input
                  type="number"
                  label={dictionary?.returnFormArticleNumberInputLabel}
                  required
                  id={`ItemNo-${index + 1}`}
                  name={`ItemNo-${index + 1}`}
                  fullWidth
                  inputMode="numeric"
                  data-1p-ignore
                  classNames={{
                    input: isInputError(`ItemNo-${index + 1}`)
                      ? 'border-error'
                      : '',
                  }}
                />
                <ValidationError
                  className="mt-2 text-sm"
                  field={`ItemNo-${index + 1}`}
                  errors={state.errors}
                />
              </div>
              <div>
                <Select
                  name={`Reason-${index + 1}`}
                  placeholder={dictionary?.returnFormReasonForReturnPlaceholder}
                  options={reasons}>
                  <ValidationError
                    className="mt-2 text-sm"
                    field={`Reason-${index + 1}`}
                    errors={state.errors}
                  />
                </Select>
              </div>
            </div>
            <div className="mt-2 flex w-full items-center justify-end">
              {index > 0 ? (
                <button
                  type="button"
                  className="tracking-tighter underline"
                  onClick={() =>
                    setItems((old) => old.filter((i) => i !== item))
                  }>
                  {dictionary?.remove}
                </button>
              ) : null}
            </div>
          </fieldset>
        ))}
      </div>

      <fieldset className="mt-2">
        <div className="mb-[42px] flex items-center gap-1">
          <Icon name="greenPlusmark" size={16} />
          <button
            type="button"
            className="inline-block text-sm font-bold"
            onClick={() => setItems((i) => [...i, new Date().getTime()])}>
            {dictionary?.returnFormAddItemButtonText}
          </button>
        </div>
        <Checkbox
          checkedIcon={<Icon name="checkIcon" color="white" size={16} />}
          classNames={{ label: 'text-sm' }}
          name="confirmation"
          {...{ required: true }}>
          {dictionary?.returnFormConfirmationCheckboxText}
        </Checkbox>
        <div className="mt-5 grid grid-cols-1 gap-3 md:grid-cols-2">
          <Button
            type="submit"
            disabled={state.submitting}
            fullWidth
            className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 h-10 w-full grow bg-black text-sm text-white disabled:opacity-100 md:w-[340px]">
            {dictionary?.returnFormCreateReturnButtonText}
          </Button>
        </div>
      </fieldset>
    </form>
  );
}
