'use client';

import { useForm, ValidationError } from '@formspree/react';
import { Dictionary } from '@ts/dictionary';
import { useMemo } from 'react';
import Input from '@ui/components/inputs/Input';
import { Button } from '@ngg/components';
import Select from '@ui/components/inputs/Select';
import Textarea from '@ui/components/inputs/Textarea';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';

export default function ContactForm({
  dictionary,
  reference,
}: {
  reference?: string | null | undefined;
  dictionary: Dictionary;
}) {
  const [state, handleSubmit] = useForm(reference || '');

  const isInputError = (inputName: string) =>
    // @ts-ignore
    state?.errors?.some((error) => error.field === inputName);

  const cases = useMemo(
    () => [
      {
        id: 1,
        value: dictionary?.contactFormValueQuote,
        label: dictionary?.contactFormLabelQuote,
      },
      {
        id: 2,
        value: dictionary?.contactFormValueOngoing,
        label: dictionary?.contactFormLabelOngoing,
      },
      {
        id: 3,
        value: dictionary?.contactFormValueQuestions,
        label: dictionary?.contactFormLabelQuestions,
      },
      {
        id: 4,
        value: dictionary?.contactFormValueClaims,
        label: dictionary?.contactFormLabelClaims,
      },
      {
        id: 5,
        value: dictionary?.contactFormValueOther,
        label: dictionary?.contactFormLabelOther,
      },
    ],
    [dictionary],
  );

  if (state.succeeded) {
    return (
      <>
        <Heading className="text-lg">{dictionary?.formSuccessHeading}</Heading>
        <Paragraph className="mt-4 text-sm">
          {dictionary?.formSuccessText}
        </Paragraph>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="not-prose max-w-4xl space-y-3">
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        <div>
          <Input
            id="name"
            name="Name"
            type="text"
            required
            fullWidth
            label={dictionary?.formNameInputLabel}
            classNames={{
              input: isInputError('Name') ? 'border border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Name"
            errors={state.errors}
          />
        </div>
        <div>
          <Input
            id="email"
            name="Email"
            type="email"
            required
            fullWidth
            label={dictionary?.formEmailInputLabelShort}
            classNames={{
              input: isInputError('Email') ? 'border border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Email"
            errors={state.errors}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        <div>
          <Input
            id="phonenumber"
            name="Phonenumber"
            type="text"
            required
            fullWidth
            label={dictionary?.contactFormPhoneInputLabel}
            classNames={{
              input: isInputError('Phonenumber') ? 'border border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Phonenumber"
            errors={state.errors}
          />
        </div>
        <div>
          <Input
            id="orderNumber"
            name="Order Number"
            type="text"
            fullWidth
            label={dictionary?.contactFormOrderNumberLabel}
            classNames={{
              input: isInputError('Order Number') ? 'border border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Order Number"
            errors={state.errors}
          />
        </div>
      </div>

      <Select
        name="case"
        placeholder={dictionary?.contactFormSelectPlaceholder}
        options={cases}
        required>
        <ValidationError
          className="mt-2 text-sm"
          field="case"
          errors={state.errors}
        />
      </Select>

      <Textarea
        name="Message"
        id="message"
        required
        label={dictionary?.contactFormMessageTextareaLabel}
        fullWidth
        resizable="none"
        classNames={{
          textarea: isInputError('Message') ? 'border border-error' : '',
        }}
      />
      <ValidationError
        className="mt-2 text-sm"
        field="Message"
        errors={state.errors}
      />

      <Button
        type="submit"
        disabled={state.submitting}
        fullWidth
        className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 md:w-[340px] h-10 w-full grow bg-black text-sm text-white disabled:opacity-100">
        {dictionary?.contactFormActionButtonText}
      </Button>

      {/* Honeypot */}
      <input type="text" name="_gotcha" className="hidden" />
    </form>
  );
}
